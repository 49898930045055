import { createSlice } from '@reduxjs/toolkit';
import { DynamoConnector } from '../../DynamoConnector';

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        isFetching: false,
        authors: [],
        commanders: [],
        decks: [],
        cards: [],
    },
    reducers: {
        setSearchValue: (state, action) => {
            if (action) {
                state[action.payload.field] = action.payload.list.map((item) => {
                    return {
                        name: item?.name || item.id,
                        id: item.id,
                    };
                });
            }

            state.isFetching = false;
        },
        setIsFetching: (state, action) => {
            state.isFetching = action;
        },
        resetSearchField: (state, action) => {
            state[action] = [];
        },
    },
});

// Actions
export const fetchList =
    (query, field, index = null) =>
    (dispatch) => {
        const sanitizeIdString = (val, skipHyphenConversion = false) => {
            try {
                if (!val) {
                    return '';
                }

                const diacritics = [
                    { char: 'A', base: /[\300-\306]/g },
                    { char: 'a', base: /[\340-\346]/g },
                    { char: 'E', base: /[\310-\313]/g },
                    { char: 'e', base: /[\350-\353]/g },
                    { char: 'I', base: /[\314-\317]/g },
                    { char: 'i', base: /[\354-\357]/g },
                    { char: 'O', base: /[\322-\330]/g },
                    { char: 'o', base: /[\362-\370]/g },
                    { char: 'U', base: /[\331-\334]/g },
                    { char: 'u', base: /[\371-\374]/g },
                    { char: 'N', base: /[\321]/g },
                    { char: 'n', base: /[\361]/g },
                    { char: 'C', base: /[\307]/g },
                    { char: 'c', base: /[\347]/g },
                ];

                diacritics.forEach(function (letter) {
                    val = val.replace(letter.base, letter.char);
                });

                val = val
                    .toLowerCase()
                    .replace(/,|'/g, '')
                    .replace(/ /g, '_')
                    .replace(/"/g, '')
                    .replace(/.\/\/./g, '_')
                    .replace(/-\/\/.*/g, '')
                    .replace(/-/g, '_')
                    .trim();
            } catch (error) {
                console.log(`[CACHE][ERROR] unable to sanitize id string: ${JSON.stringify(val)}`);
                throw error;
            }

            return val;
        };

        console.log(query);
        dispatch(setIsFetching(true));

        DynamoConnector.getShallowList(sanitizeIdString(query), field, index, (list) => {
            dispatch(setSearchValue({ field, list: list?.Items || [] } || null));
        });
    };

export const { setSearchValue, setIsFetching, resetSearchField } = searchSlice.actions;

export default searchSlice.reducer;
