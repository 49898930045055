import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Text } from '@adobe/react-spectrum';
import User from '@spectrum-icons/workflow/User';
import {
    setLeaderboardSortBy,
    fetchAll,
    SORT_MAP,
    setLeaderboardFilters,
    leaderboardFetchFiltered,
    setSelectedListKey,
    setSelectedListIndex,
    setLeaderboardSortDirection,
} from '../../data/redux/slices/leaderboardSlice';
import './LeaderBoard.css';
import { resetPreventNextLoadMore, setAppRoute } from '../../data/redux/slices/appSlice';
import { Helmet } from 'react-helmet';
import { ImportStatusBar } from '../importStatusBar/ImportStatusBar';
import { SubmitForm } from '../submitform/SubmitForm';
import { ResultsListing } from '../resultsListing/ResultsListing';
import { resultsCellRenderers } from '../resultsListing/renderers/ResultsCellRenderers';
import { resultsColumns } from '../resultsListing/columns/ResultsColumns';
import { FilterPanel } from '../filterpanel/FilterPanel';
import { resultsColumnRenderers } from '../resultsListing/renderers/ResultsColumnRenderers';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import { getAnalytics, logEvent } from 'firebase/analytics';

export function LeaderBoard() {
    const dispatch = useDispatch();
    const listItems = useSelector((state) => state?.leaderboard?.listItems);
    const nextCursor = useSelector((state) => state?.leaderboard?.nextCursor);
    const isFetching = useSelector((state) => state?.leaderboard?.isFetching);
    const loadingState = useSelector((state) => state?.leaderboard?.loadingState);
    const searchFilters = useSelector((state) => state?.leaderboard?.filters);
    const sortBy = useSelector((state) => state?.leaderboard?.sortBy);
    const preventNextLoadMore = useSelector((state) => state?.app?.preventNextLoadMore);
    const previewIsShowing = useSelector((state) => state.preview.isShowing);
    const isImporting = useSelector((state) => state.import.isImporting);
    const deck = useSelector((state) => state.preview.previewDeck);
    const selectedListKey = useSelector((state) => state.leaderboard.selectedListKey);
    const selectedListIndex = useSelector((state) => state.leaderboard.selectedListIndex);

    document.getElementById('body').className = previewIsShowing ? 'bodyNoScroll' : 'body';

    const handleSortChange = (sortIndex) => {
        dispatch(setLeaderboardSortBy(SORT_MAP[sortIndex], searchFilters));
        dispatch(fetchAll(null, searchFilters, true, SORT_MAP[sortIndex], sortDirection));
    };

    const sortDirection = useSelector((state) => state.leaderboard.sortDirection);
    const handleSortDirectionChange = (direction) => {
        dispatch(setLeaderboardSortDirection(direction));

        dispatch(fetchAll(null, searchFilters, true, sortBy, direction));
    };

    const handleFiltersChange = (filters) => {
        dispatch(leaderboardFetchFiltered(filters, sortBy));
    };

    const handleSelectionChange = (evn) => {
        dispatch(setSelectedListKey(evn));
    };

    const handleIndexSelection = (evn) => {
        dispatch(setSelectedListIndex(evn));
    };

    const handleLoadMore = () => {
        if (preventNextLoadMore) {
            dispatch(resetPreventNextLoadMore());
            return;
        }

        if (!isFetching && nextCursor !== null) {
            dispatch(fetchAll(nextCursor, searchFilters, false, sortBy, sortDirection));
        }
    };

    let helmetName = `Leaderboard`;
    let helmetCanonical = `https://www.commandersalt.com`;
    let helmetDescription = `The saltiest MtG decks on the planet!`;
    let helmetTitle = `Commandersalt.com - The saltiest MtG decks on the planet!`;

    // TODO: remove this when details moved out of popup
    if (previewIsShowing) {
        helmetName = `Details ${deck?.title ? '> ' + deck.title : ''}`;
        helmetCanonical = `https://www.commandersalt.com`;
        helmetDescription = `MtG Commander deck ${deck?.author?.username ? 'by ' + deck?.author?.username : ''}`;
        helmetTitle = `Commandersalt.com - MtG Commander deck ${
            deck?.author?.username ? 'by ' + deck?.author?.username : ''
        }`;
    }

    const handleNewClick = () => {
        logEvent(getAnalytics(), 'new_feature_click', { url: deck?.deckUri });
        dispatch(setAppRoute({ route: `/changelog` }));
    };

    const handleAlgorithmClick = () => {
        logEvent(getAnalytics(), 'leaderboard_algorithm', { url: deck?.deckUri });
        // dispatch(setAppRoute({ route: `/changelog` }));
        // TODO: what the hell is happening here
        dispatch(setAppRoute({ route: `/algorithm` }));
    };

    return (
        <Flex direction="column">
            <Helmet>
                <title>{helmetName}</title>
                <meta name="creator" content="Ben Rimbey" />
                <link rel="canonical" href={helmetCanonical} />
                <meta name="description" content={helmetDescription} />
                <meta name="twitter:title" content={helmetTitle} />
                <meta property="og:site_name" content="Commandersalt.com" />
                <meta property="og:title" content={helmetTitle} />
                <meta property="og:image" content="https://www.commandersalt.com/chef-kiss-meta-image.png" />
                <meta property="og:description" content={helmetDescription} />
            </Helmet>
            <BreadCrumbs />
            <span onClick={handleNewClick}>
                <Flex
                    direction="row"
                    columnGap={10}
                    marginTop={10}
                    justifyContent="center"
                    alignItems={'center'}
                    alignSelf={'center'}
                    alignContent={'center'}
                    UNSAFE_className={'newFeatureLabel'}
                >
                    <Text UNSAFE_style={{ color: '#FF0000' }}>UPDATED!</Text>
                    <Text>Checkout the changelog for details</Text>
                </Flex>
            </span>
            <Flex direction={'row'} justifyContent={'space-around'} width={'100vw'} marginTop={50} marginBottom={50}>
                {
                    isImporting ? <ImportStatusBar /> : <SubmitForm /> // initialListUrl={param} />
                }
            </Flex>
            <FilterPanel onFiltersChange={handleFiltersChange} filters={searchFilters} />
            <Flex
                alignContent={'center'}
                justifyContent={'center'}
                columnGap={5}
                direction={'row'}
                marginTop={-10}
                UNSAFE_className={'previewPowerLevelChangedLabel'}
                wrap
            >
                <span style={{ fontSize: '12px' }} onClick={handleAlgorithmClick}>
                    How are power level scores determined?
                </span>
            </Flex>
            <ResultsListing
                sortBy={sortBy}
                sortDirection={sortDirection}
                onFiltersChange={handleFiltersChange}
                filters={searchFilters}
                handleSortChange={handleSortChange}
                handleSortDirectionChange={handleSortDirectionChange}
                handleSelectionChange={handleSelectionChange}
                handleIndexSelection={handleIndexSelection}
                selectedListIndex={selectedListIndex}
                selectedListIndexPrefix={'leaderboard_'}
                selectedListKey={selectedListKey}
                routePath={`/`}
                allowDeckPreview={true}
                showFilters={false}
                columns={resultsColumns.LEADERBOARD()}
                columnRenderer={resultsColumnRenderers.LEADERBOARD}
                listItems={listItems}
                cellRenderer={resultsCellRenderers.LEADERBOARD}
                previewIsShowing={previewIsShowing}
                handleLoadMore={handleLoadMore}
                loadingState={loadingState}
            />
        </Flex>
    );
}
